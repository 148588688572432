import { MdDeleteOutline } from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

import { BaseLoading } from '../BaseLoading';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 5px;
`;

interface IImageContainerProps {
  hasImage: boolean;
}

export const ImageContainer = styled.div<IImageContainerProps>`
  align-items: center;
  color: ${colors.gray300};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  ${({ hasImage }) =>
    hasImage &&
    css`
      height: 6rem;
    `}
`;

export const ImageRemoveLink = styled.a`
  font-size: 0.813rem;
  white-space: nowrap;
  margin: 5px 0;
  color: ${colors.blue400};
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: ${colors.blue300};
  }
`;

export const Label = styled.legend`
  font-size: 0.77rem;
  margin-left: 0.5rem;
  padding: 1px 6px;
`;

interface IFieldsetProps {
  readonly hasLabel: boolean;
}

export const Fieldset = styled.fieldset<IFieldsetProps>`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.sm};
  display: flex;
  flex-direction: row;
  height: ${({ hasLabel }) => (hasLabel ? '3.78rem' : '3.25rem')};
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  text-overflow: ellipsis;
`;

export const Input = styled.input`
  color: ${colors.gray400};
  line-height: 1.5;
  font-family: inherit;
  outline: none;
  padding: 0 1rem;
  z-index: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  &::file-selector-button {
    background-color: ${colors.button};
    height: 40px;
    color: ${colors.white300};
    font-weight: 700;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    transition: all 0.25s;
    border: none;
    border-radius: ${radii.md};
  }
  &::file-selector-button:hover {
    filter: brightness(0.9);
  }
`;

export const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
`;

export const DeleteIcon = styled(MdDeleteOutline)`
  height: 1.75rem;
  width: 1.75rem;
  &:hover {
    color: ${colors.blue300};
  }
`;

export const Loading = styled(BaseLoading)`
  width: 40px;
`;

export const ConfirmationTitle = styled.h1`
  font-size: 1.125rem;
  margin-bottom: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  width: 100%;
`;

export const ConfirmationDescription = styled.div`
  margin: 30px 0;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  & button + button {
    margin-left: 1rem;
  }
`;