import { HiOutlineMenuAlt2, HiOutlineUserCircle } from 'react-icons/hi';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

import { BaseDropdownItem } from '../BaseDropdown';
import { Button } from '../Button';
import { LazyImage } from '../LazyImage';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
`;

export const Toolbar = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.divider};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-width: 100%;
  padding: 0;
  margin: 0;
`;

export const Profile = styled.div`
  border-left: 1px solid ${colors.divider};
  min-height: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

export const ProfileContent = styled.div`
  display: flex;
  padding: 0.1rem 0.1rem;
  vertical-align: middle;
  width: 100%;
`;

export const Content = styled.div`
  color: ${colors.headerText};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const HeaderContent = styled.header`
  align-items: center;
  border: none;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  min-height: 3rem;
`;

export const ToggleSidebar = styled.button`
  all: unset;
  align-items: center;
  border-radius: 50%;
  color: ${colors.headerText};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  height: 1.875rem;
  justify-content: center;
  width: 1.875rem;

  &:hover {
    background-color: ${colors.white300};
    filter: brightness(0.9);
  }
`;

export const MenuIcon = styled(HiOutlineMenuAlt2)`
  height: 1.3125rem;
  width: 1.3125rem;
`;

export const UserIcon = styled(HiOutlineUserCircle)`
  height: 2rem;
  width: 2rem;
`;

export const LazyImageCustom = styled(LazyImage)`
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  object-fit: cover;
`;

export const UserName = styled.p``;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  background: ${colors.backgroundDarker};

  & > * {
    margin: 1.875rem;
    padding: 1rem;
    border-radius: ${radii.xl};
  }
`;

export const DropdownButtonProfileContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  min-width: 100%;
`;

export const ButtonProfileDropdown = styled(Button)`
  color: ${colors.headerText};
  background: transparent !important;
  width: 100%;
  justify-content: start;

  &:hover {
    background-color: ${colors.backgroundHover} !important;
    filter: brightness(0.9);
  }
`;

export const ButtonContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0.25rem;
`;

export const DropdownItem = styled(BaseDropdownItem)`
  & + & {
    margin-bottom: 0.25rem;
  }
`;

export const ArrowDown = styled(MdKeyboardArrowDown)`
  height: 1rem;
  width: 1rem;
`;

export const DropdownItemAsLink = styled(Link)`
  color: ${colors.text};
  display: flex;
  flex-grow: 1;
  padding: 1rem;
  text-decoration: none;

  &:visited {
    color: ${colors.text};
  }
`;

export const HeaderIconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 0.5rem;
`;

export const ToggleDashboardButton = styled(Button)`
  color: ${colors.headerText};
  background-color: transparent;
  justify-content: start;
  padding: 0;

  &:hover {
    background-color: ${colors.backgroundHover};
    filter: brightness(0.9);
  }
`;
