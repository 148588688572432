import { useCallback, useState } from 'react';
import { type DropzoneOptions } from 'react-dropzone';
import { useFieldArray, useForm } from 'react-hook-form';

import {
  FileDropzone,
  FormBase,
  FormModal,
  type IFormModalProps,
  UnexpectedError,
  formatSize,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  type UploadFileSchema,
  uploadMultipleFileSchema,
} from '../../../../../../formSchemas/documentSchema';
import {
  ActionButton,
  Checkbox,
  DeleteIcon,
  ErrorMessage,
  FileContainer,
  FileDetails,
  FileDropzoneContainer,
  FileIconCustom,
  FileName,
  FileSize,
  FilesContainer,
  HighlightText,
  InputText,
} from './styles';

export interface IUploadFileModalProps
  extends Partial<Omit<IFormModalProps, 'onAccept' | 'onOpenChange'>> {
  dropzoneOptions?: DropzoneOptions;
  onAccept?: (
    uploaded: File[],
    description: string[],
    highlighted: boolean[]
  ) => Promise<void>;
  onDecline?: () => void;
  onOpenChange?: () => void;
  open: boolean;
  waitToOpen?: boolean;
}

export const UploadMultipleFileModal = ({
  children,
  dropzoneOptions,
  errorMessage,
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  zIndex,
  onOpenChange,
  ...props
}: IUploadFileModalProps): JSX.Element | null => {
  const [loadingUploadFile, setLoadingUploadFile] = useState<boolean>(false);

  const [errorOnAccept, setErrorOnAccept] = useState('');

  const documents = useForm<UploadFileSchema>({
    resolver: zodResolver(uploadMultipleFileSchema),
    mode: 'all',
    defaultValues: {
      files: [],
    },
  });

  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
    clearErrors,
  } = documents;

  const files = watch('files');

  const resetForm = useCallback(() => {
    reset();
    setValue('files', []);
    setLoadingUploadFile(false);
  }, [reset, setValue]);

  const handleUploadFile = (data: UploadFileSchema) => {
    setLoadingUploadFile(true);
    const { files } = data;
    onAccept?.(
      files.map((item) => item.file),
      files.map((item) => item.description),
      files.map((item) => item.highlighted)
    )
      .then(() => {
        setLoadingUploadFile(false);
        resetForm();
      })
      .catch((error: Error) => {
        if (!(error instanceof Error)) {
          setErrorOnAccept(UnexpectedError);
          setLoadingUploadFile(false);
          return;
        }
        setErrorOnAccept(error.message);
        setLoadingUploadFile(false);
      });
  };

  const handleDeclineUploadFile = useCallback(() => {
    onDecline?.();
    onOpenChange?.();
  }, [onDecline, onOpenChange]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      clearErrors('files');
      const newFiles = acceptedFiles.map((file) => ({
        file,
        description: '',
        highlighted: false,
      }));

      setValue('files', [...files, ...newFiles]);
    },
    [clearErrors, files, setValue]
  );

  const toggleHighlight = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles[index].highlighted = !updatedFiles[index].highlighted;
    setValue('files', updatedFiles);
  };

  const { fields, remove } = useFieldArray({
    name: 'files',
    control,
  });

  const handleRemoveFile = (index: number) => {
    remove(index);
    const updatedFiles = files.filter((_, i) => i !== index);
    setValue('files', updatedFiles);
  };

  return !open ? null : (
    <FormBase.Provider {...documents}>
      <FormModal
        loading={loadingUploadFile}
        dataCy="upload-file-modal"
        acceptText="Upload"
        mainText="Upload file"
        {...props}
        errorMessage={errorMessage ?? errorOnAccept}
        open={!waitToOpen}
        onAccept={handleSubmit(handleUploadFile)}
        onDecline={handleDeclineUploadFile}
        onOpenChange={handleDeclineUploadFile}
        zIndex={zIndex}
      >
        <FormBase.Content>
          {children}

          {errors.files && <ErrorMessage>{errors.files.message}</ErrorMessage>}
          <FileDropzoneContainer>
            <FileDropzone
              onDrop={handleDrop}
              {...dropzoneOptions}
              disabled={props.disabled ?? loadingUploadFile}
            />
          </FileDropzoneContainer>

          {!!files.length && (
            <>
              <HighlightText>
                {'Use the checkboxes to Highlight document on Client Dashboard'}
              </HighlightText>
              <FilesContainer>
                {fields.map((file, index) => (
                  <FileContainer key={index}>
                    <FormBase.CheckboxContent
                      label=""
                      htmlFor={`highlightDocumentCheckbox-${index}`}
                    >
                      <Checkbox
                        id={`files.${index}.highlighted`}
                        checked={file.highlighted}
                        name={`files.${index}.highlighted`}
                        dataCy={`highlight-document-checkbox-${index}`}
                        onCheckedChange={() => {
                          toggleHighlight(index);
                        }}
                      ></Checkbox>
                    </FormBase.CheckboxContent>
                    <FileIconCustom file={file.file} dataCy={'logo-image'} />
                    <FileDetails>
                      <FileName>{file.file.name}</FileName>
                      <FileSize>{formatSize(file.file.size)}</FileSize>
                      <InputText
                        type="text"
                        id={`files.${index}.description`}
                        name={`files.${index}.description`}
                        placeholder="Description"
                        disabled={loadingUploadFile}
                        data-cy={`input-description-${index}`}
                      />
                      {errors.files?.[index]?.description && (
                        <ErrorMessage>
                          {errors.files?.[index]?.description?.message}
                        </ErrorMessage>
                      )}
                    </FileDetails>
                    <ActionButton
                      disabled={loadingUploadFile}
                      type="button"
                      onClick={() => {
                        handleRemoveFile(index);
                      }}
                    >
                      <DeleteIcon />
                    </ActionButton>
                  </FileContainer>
                ))}
              </FilesContainer>
              {Boolean(errors.files?.root) && (
                <ErrorMessage>{errors.files?.root?.message}</ErrorMessage>
              )}
            </>
          )}
        </FormBase.Content>
      </FormModal>
    </FormBase.Provider>
  );
};
