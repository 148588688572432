import styled from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';
import { Root } from '@radix-ui/react-label';

export const Content = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  padding-right: 0.5rem;
`;

export const Container = styled.fieldset`
  all: unset;
  align-items: center;
  border: none;
  display: flex;
  flex-direction: row;
  min-height: 1.5rem;
`;

export const Label = styled(Root)`
  color: ${colors.labelText};
  font-size: 0.875rem;
  line-height: 1.3;
  overflow: hidden;
  padding: 1px 6px;
  user-select: none;
`;
