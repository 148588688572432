import { useCallback, useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { type IUserDTO, useAuth } from '@gbs-monorepo-packages/auth';
import {
  Button,
  DefaultDescription,
  FormBase,
  Logger,
  ServerError,
  UploadImageModal,
  useToast,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  type PasswordSchema,
  type ProfileSettingSchema,
  passwordSchema,
  profileSettingSchema,
} from '../../formSchemas/profileSettingSchema';
import { type IApiThrowsError } from '../../services/api';
import {
  changePassword,
  deleteMemberProfile,
  profileUpdate,
} from '../../services/users';
import {
  ButtonContainer,
  ErrorMessage,
  Header,
  LockedIcon,
  MainContainer,
  MainContent,
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
  TitlePage,
  UnLockedIcon,
} from './styles';

interface IError {
  firstName?: string;
  lastName?: string;
  other?: string;
}

interface IErrorPassword {
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
  other?: string;
}

export const ProfileSettings = (): JSX.Element => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);

  const { user, syncUserData } = useAuth();
  const { addToast } = useToast();

  const [error, setError] = useState<IError>({});
  const [loading, setLoading] = useState(false);

  const [errorPassword, setErrorPassword] = useState<IErrorPassword>({});
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [loadingDeleteImage, setLoadingDeleteImage] = useState<boolean>(false);

  const profileSetting = useForm<ProfileSettingSchema>({
    resolver: zodResolver(profileSettingSchema),
  });

  const {
    handleSubmit: submitProfileSetting,
    formState: { errors: profileSettingError },
    setValue: setProfileSettingValue,
    watch: watchProfileSetting,
  } = profileSetting;

  const valuesProfileSetting = watchProfileSetting();

  const passwordSetting = useForm<PasswordSchema>({
    resolver: zodResolver(passwordSchema),
  });

  const {
    handleSubmit: submitPasswordSetting,
    formState: { errors: passwordSettingError },
    watch: watchPasswordSetting,
    setError: setPasswordSettingError,
    reset: resetPasswordSetting,
  } = passwordSetting;

  const valuePasswordSetting = watchPasswordSetting();

  const onSubmitProfileSetting: SubmitHandler<ProfileSettingSchema> = (
    data: ProfileSettingSchema
  ) => {
    const aux = {
      ...data,
    };
    if (imageFile) {
      aux.profile = imageFile;
    }

    setLoading(true);

    void profileUpdate({
      email: user?.email ?? '',
      firstName: data.firstName,
      lastName: data.lastName,
      profile: aux.profile,
    })
      .then((response) => {
        if (user) {
          setLoading(false);
          addToast({
            title: 'Success',
            description: 'Profile updated!',
            styleType: 'success',
            dataCy: 'success-toast',
          });

          syncUserData({
            companyId: user.companyId,
            email: user.email,
            firstName: data?.firstName,
            lastName: data?.lastName,
            id: user.id,
            permissions: user.permissions,
            roles: user.roles,
            status: user.status,
            accountFile: response.accountFile,
          });
        }
      })
      .catch((error: IApiThrowsError) => {
        setTimeout(() => {
          setLoading(false);
          if (error.response && error.response.status >= 500) {
            setError({
              other: 'Server error, please try again later',
            });
          } else {
            setError({
              other: ServerError,
            });
          }

          addToast({
            title: 'Something went wrong',
            description: 'There was an error updating the profile',
            styleType: 'error',
            dataCy: 'error-toast',
          });
        }, 2000);
      });
  };

  const onSubmitPassword: SubmitHandler<PasswordSchema> = (
    data: PasswordSchema
  ) => {
    setLoadingPassword(true);
    void changePassword({
      email: user?.email ?? '',
      password: data.newPassword,
      currentPassword: data.currentPassword,
    })
      .then(() => {
        if (user) {
          setLoadingPassword(false);
          addToast({
            title: 'Success',
            description: 'Password updated!',
            styleType: 'success',
            dataCy: 'success-toast',
          });

          resetPasswordSetting();
        }
      })
      .catch((error: IApiThrowsError) => {
        setTimeout(() => {
          setLoadingPassword(false);

          if (error.response && error.response.status >= 500) {
            setErrorPassword({
              other: ServerError,
            });
          } else if (error.response?.status === 400) {
            const message = error.response?.data.error.message;
            if (message?.includes('current') ?? false) {
              setPasswordSettingError('currentPassword', {
                message,
              });
            } else {
              setPasswordSettingError('newPassword', {
                message,
              });
            }

            return;
          }

          addToast({
            title: 'Something went wrong',
            description: 'There was an error updating the profile',
            styleType: 'error',
            dataCy: 'error-toast',
          });
        }, 2000);
      });
  };

  const handlerShowCurrentPassword = () => {
    setShowCurrentPassword((showCurrentPassword) => !showCurrentPassword);
  };

  const handlerShowNewPassword = () => {
    setShowNewPassword((showNewPassword) => !showNewPassword);
  };

  const handlerShowConfirmPassword = () => {
    setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);
  };

  const handleDeleteImage = useCallback(
    (user: IUserDTO) => {
      setLoadingDeleteImage(true);

      deleteMemberProfile(user.id)
        .then(() => {
          setLoadingDeleteImage(false);
          addToast({
            title: 'Image deleted',
            description: `Profile image successfully deleted.`,
            styleType: 'success',
          });

          syncUserData({
            ...user,
            accountFile: null,
          });
        })
        .catch((err) => {
          setLoadingDeleteImage(false);
          addToast({
            title: 'Error on delete image',
            description: DefaultDescription,
            styleType: 'error',
          });
          Logger.debug('err on delete image: ', err);
        });
    },
    [addToast, syncUserData]
  );

  useEffect(() => {
    setError({});
    setProfileSettingValue('firstName', user?.firstName ?? '');
    setProfileSettingValue('lastName', user?.lastName ?? '');
    setProfileSettingValue('email', user?.email ?? '');
    setProfileSettingValue('companyId', user?.companyId ?? 0);
    setProfileSettingValue('id', user?.id ?? 0);
    setProfileSettingValue('roles', user?.roles ?? ['']);
    setProfileSettingValue('status', user?.status ?? '');
  }, [setProfileSettingValue, user]);

  return (
    <MainContainer data-cy="page-container">
      <Header>
        <TitlePage data-cy="title-settingsUserLogged">Settings</TitlePage>
      </Header>
      <MainContent data-cy="profile-settings-form-container">
        <TabsRoot defaultValue="tab1">
          <TabsList aria-label="Manage your account">
            <TabsTrigger data-cy="tab-account" value="tab1">
              Account
            </TabsTrigger>
            <TabsTrigger data-cy="tab-password" value="tab2">
              Password
            </TabsTrigger>
          </TabsList>

          <TabsContent value="tab1" data-cy="tab-content-account">
            <FormBase.Provider {...profileSetting}>
              <FormBase.Root
                onSubmit={submitProfileSetting(onSubmitProfileSetting)}
                data-cy="profile-settings-form"
              >
                <FormBase.Content>
                  <FormBase.InputContent
                    filled={!!valuesProfileSetting?.firstName}
                    inputRef="first-name"
                    label="First Name"
                    errorMessage={profileSettingError.firstName?.message}
                    dataCy="label-firstName"
                  >
                    <FormBase.InputText
                      dataCy="input-firstName"
                      id="first-name"
                      name="firstName"
                      type="text"
                      value={valuesProfileSetting?.firstName ?? ''}
                    />
                  </FormBase.InputContent>

                  <FormBase.InputContent
                    filled={!!valuesProfileSetting?.lastName}
                    inputRef="last-name"
                    label="Last Name"
                    errorMessage={profileSettingError.lastName?.message}
                    dataCy="label-lastName"
                  >
                    <FormBase.InputText
                      dataCy="input-lastName"
                      id="last-name"
                      name="lastName"
                      type="text"
                      value={valuesProfileSetting?.lastName ?? ''}
                    />
                  </FormBase.InputContent>

                  <UploadImageModal
                    label="Profile Image"
                    onSelectImage={(file) => {
                      setImageFile(file);
                    }}
                    onRemoveImagePreview={() => {
                      setImageFile(null);
                    }}
                    onRemoveImage={() => {
                      if (user) handleDeleteImage(user);
                    }}
                    imageSrc={user?.accountFile?.path}
                    loadingDeleteImage={loadingDeleteImage || loading}
                  />

                  <ErrorMessage data-cy={errorPassword.other}>
                    {error.other}
                  </ErrorMessage>

                  <ButtonContainer>
                    <Button
                      dataCy="button-updateProfile"
                      type="submit"
                      loading={loading}
                    >
                      Update Profile
                    </Button>
                  </ButtonContainer>
                </FormBase.Content>
              </FormBase.Root>
            </FormBase.Provider>
          </TabsContent>

          <TabsContent value="tab2" data-cy="tab-content-password">
            <FormBase.Provider {...passwordSetting}>
              <FormBase.Root
                onSubmit={submitPasswordSetting(onSubmitPassword)}
                data-cy="password-settings-form"
              >
                <FormBase.Content>
                  <FormBase.InputContent
                    filled={!!valuePasswordSetting?.currentPassword}
                    inputRef="current-password"
                    label="Current Password"
                    errorMessage={passwordSettingError.currentPassword?.message}
                    dataCy="label-currentPassword"
                  >
                    <FormBase.InputText
                      dataCy="input-currentPassword"
                      id="current-password"
                      name="currentPassword"
                      type={showCurrentPassword ? 'text' : 'password'}
                    />
                    <FormBase.InputActions
                      icon={showCurrentPassword ? LockedIcon : UnLockedIcon}
                      onClick={handlerShowCurrentPassword}
                      dataCy={
                        showCurrentPassword ? 'icon-locked' : 'icon-unlocked'
                      }
                    />
                  </FormBase.InputContent>

                  <FormBase.InputContent
                    filled={!!valuePasswordSetting?.newPassword}
                    inputRef="new-password"
                    label="New Password"
                    errorMessage={passwordSettingError.newPassword?.message}
                    dataCy="label-newPassword"
                  >
                    <FormBase.InputText
                      dataCy="input-newPassword"
                      id="new-password"
                      name="newPassword"
                      type={showNewPassword ? 'text' : 'password'}
                    />
                    <FormBase.InputActions
                      icon={showNewPassword ? LockedIcon : UnLockedIcon}
                      onClick={handlerShowNewPassword}
                      dataCy={showNewPassword ? 'icon-locked' : 'icon-unlocked'}
                    />
                  </FormBase.InputContent>

                  <FormBase.InputContent
                    filled={!!valuePasswordSetting?.confirmPassword}
                    inputRef="confirm-password"
                    label="Confirm Password"
                    errorMessage={passwordSettingError.confirmPassword?.message}
                    dataCy="label-confirmPassword"
                  >
                    <FormBase.InputText
                      dataCy="input-confirmPassword"
                      id="confirm-password"
                      name="confirmPassword"
                      type={showConfirmPassword ? 'text' : 'password'}
                    />
                    <FormBase.InputActions
                      icon={showConfirmPassword ? LockedIcon : UnLockedIcon}
                      onClick={handlerShowConfirmPassword}
                      dataCy={
                        showConfirmPassword ? 'icon-locked' : 'icon-unlocked'
                      }
                    />
                  </FormBase.InputContent>

                  <ErrorMessage data-cy={errorPassword.other}>
                    {errorPassword.other}
                  </ErrorMessage>

                  <ButtonContainer>
                    <Button
                      dataCy="button-changePassword"
                      type="submit"
                      loading={loadingPassword}
                    >
                      Change password
                    </Button>
                  </ButtonContainer>
                </FormBase.Content>
              </FormBase.Root>
            </FormBase.Provider>
          </TabsContent>
        </TabsRoot>
      </MainContent>
    </MainContainer>
  );
};
