import { useCallback, useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import {
  DefaultDescription,
  FormBase,
  FormModal,
  Logger,
  UploadImageModal,
  useToast,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  type UsersUpdateSchema,
  userUpdateSchema,
} from '../../../../formSchemas/userSchema';
import { deleteMemberProfile } from '../../../../services/users';

interface IUpdateTeammatesProps {
  onAccept: (data: UsersUpdateSchema) => void;
  onDecline: () => void;
  onProfileChange: (value: string, userId: number) => void;
  open: boolean;
  loading: boolean;
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  currentRole: string;
  profileSrc: string;
}

export const ModalUpdateUsers = ({
  onAccept,
  onDecline,
  onProfileChange,
  open,
  loading = false,
  id,
  email,
  firstName,
  lastName,
  phone,
  currentRole,
  profileSrc,
}: IUpdateTeammatesProps): JSX.Element => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [loadingDeleteImage, setLoadingDeleteImage] = useState<boolean>(false);
  const { addToast } = useToast();

  const updateUsers = useForm<UsersUpdateSchema>({
    resolver: zodResolver(userUpdateSchema),
  });

  const {
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = updateUsers;

  const values = watch();

  const resetForm = useCallback(() => {
    setValue('email', email);
    setValue('firstName', firstName);
    setValue('lastName', lastName);
    setValue('phone', phone);
    setValue('currentRole', currentRole);
    setImageFile(null);
  }, [email, firstName, lastName, phone, currentRole, setValue]);

  const handleDeclineAddTeammates = useCallback(() => {
    resetForm();
    onDecline?.();
  }, [onDecline, resetForm]);

  const handleDeleteImage = useCallback(
    (id: number) => {
      setLoadingDeleteImage(true);

      deleteMemberProfile(id)
        .then(() => {
          setLoadingDeleteImage(false);
          setImageFile(null);
          onProfileChange('', id);
          addToast({
            title: 'Image deleted',
            description: `Profile image successfully deleted.`,
            styleType: 'success',
          });
        })
        .catch((err) => {
          setLoadingDeleteImage(false);
          addToast({
            title: 'Error on delete image',
            description: DefaultDescription,
            styleType: 'error',
          });
          Logger.debug('err on delete image: ', err);
        });
    },
    [addToast]
  );

  useEffect(() => {
    if (!loading) {
      resetForm();
    }
  }, [loading]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const onSubmit: SubmitHandler<UsersUpdateSchema> = (
    data: UsersUpdateSchema
  ) => {
    const userAux = {
      ...data,
    };

    if (imageFile) {
      userAux.profile = imageFile;
    }
    onAccept?.(userAux);
  };

  return !open ? (
    <></>
  ) : (
    <FormBase.Provider {...updateUsers}>
      <FormModal
        acceptText="Update User"
        declineText="Cancel"
        open={open}
        dataCy="title-editUser"
        mainText="Edit User"
        onOpenChange={handleDeclineAddTeammates}
        onDecline={handleDeclineAddTeammates}
        onAccept={handleSubmit(onSubmit)}
        loading={loading || loadingDeleteImage}
        formId="add-clientForm"
      >
        <FormBase.Content>
          <UploadImageModal
            label="Profile Image"
            imageSrc={profileSrc}
            onSelectImage={(file) => {
              setImageFile(file);
            }}
            onRemoveImagePreview={() => {
              setImageFile(null);
            }}
            onRemoveImage={() => {
              handleDeleteImage(id);
            }}
            loadingDeleteImage={loadingDeleteImage || loading}
          />
          <FormBase.InputContent
            filled={!!values?.firstName}
            inputRef="firstNameEdit"
            label="First Name"
            errorMessage={errors.firstName?.message}
            dataCy="label-firstName"
          >
            <FormBase.InputText
              dataCy="input-firstName"
              id="firstNameEdit"
              name="firstName"
              type="text"
              autoComplete="off"
              maxLength={30}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.lastName}
            inputRef="lastNameEdit"
            label="Last Name"
            errorMessage={errors.lastName?.message}
            dataCy="label-lastName"
          >
            <FormBase.InputText
              dataCy="input-lastName"
              id="lastNameEdit"
              name="lastName"
              type="text"
              maxLength={30}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.phone}
            inputRef="phoneInvite"
            label="Phone"
            errorMessage={errors.phone?.message}
            dataCy="label-phone"
          >
            <FormBase.InputText
              dataCy="input-phone"
              id="phoneInvite"
              name="phone"
              type="text"
              maxLength={15}
            />
          </FormBase.InputContent>
        </FormBase.Content>
      </FormModal>
    </FormBase.Provider>
  );
};
