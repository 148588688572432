import type HighchartsReact from 'highcharts-react-official';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useHighchartsResize = (
  chartRef: React.RefObject<HighchartsReact.RefObject>
): { isResizing: boolean } => {
  const [isResizing, setIsResizing] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleResize = useCallback(() => {
    const containerElement = chartRef.current?.container
      .current as HTMLDivElement | null;

    if (containerElement) {
      setIsResizing(true);
      containerElement.style.width = '1px';

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        containerElement.style.width = '100%';
        setIsResizing(false);
        timeoutRef.current = null;
      }, 150);
    }
  }, [chartRef]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return { isResizing };
};
